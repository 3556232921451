<template>
  <div class="no-data" :style="viewStyle" >
    <div>
      <slot name="myslot" ></slot>
      <div>
        <img src="../assets/no_data.png" alt="暂无数据" style="width: 120px;">
      </div>
      <div class="msg">{{ msg }}</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name:'NoData',
  components: {
  },
  props: {
    msg: {
      type:String,
      required:false,
      default: '暂无数据',
    },
    width:{
      type:String,
      required:false,
    },
    height:{
      type:String,
      required:false,
    },
  },
  setup(props) {

    const viewStyle = computed(() => {
      return {
        width: props.width ? props.width + 'px' : '100%',
        height: props.height ? props.height + 'px' : '30vh',
      }
    });

    return {
      viewStyle,
    }
  },
}
</script>

<style scoped>
.no-data {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg {
  margin-top: 10px;
  color: #909399;
  font-size: 14px;
}
</style>